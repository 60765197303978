import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { urlApi } from "../../services/apirest.js";
import Headerdoc from "../Header_doc.js";
let pagina = 1;
let total_paginas = 0;
let cadena = "";
class DatosDetallesDoc extends React.Component {
    state = {
        registros: [],
        num_paginas: 0
    }

    pagina_siguiente = () => {
        let num_p = this.state.num_paginas[0].numpag;
        //console.log(num_p);
        if (num_p > pagina) {
            pagina += 1;
            let url = urlApi + "detallesdoc.php?page=" + pagina + "&docente=" + localStorage.getItem("idusuario");
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        registros: response.data[0]
                    })
                })
        }
    }

    pagina_anterior = () => {
        if (pagina !== 1) {
            pagina -= 1;
            let url = urlApi + "detallesdoc.php?page=" + pagina + "&docente=" + localStorage.getItem("idusuario");
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        registros: response.data[0]
                    })
                })
        }
    }

    
    

    componentDidMount = () => {
        let url = urlApi + "detallesdoc.php?page=" + pagina + "&docente=" + localStorage.getItem("idusuario");
        axios
            .get(url)
            .then(response => {
                this.setState({
                    registros: response.data[0],
                    num_paginas: response.data[1]
                })
                total_paginas = response.data[1][0].numpag;
                if (pagina > response.data[1][0].numpag) {
                    this.pagina_anterior();
                }
            })
    }


    ircalificaciones(id, trimestre, asi_nombre) {
        //console.log(id);
        localStorage.setItem("IdDetalle", id);
        localStorage.setItem("Trimestre", trimestre);
        localStorage.setItem("asi", asi_nombre);
        this.props.navigate('/datoscalificacionesdoc');
    }

    render() {
        return (
            <div>
                <Headerdoc />
                <div class="container">
                    <div class="d-flex justify-content-around" style={{marginBottom: "20px"}}>
                    </div>
                    <div className="container table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr className="text text-sm-center">
                                    <th hidden scope="col">ID</th>
                                    <th scope="col">Docentes</th>
                                    <th scope="col">Asignaturas</th>
                                    <th scope="col">Paralelos</th>
                                    <th scope="col">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.registros.map((value, index) => {
                                    return (
                                        <tr key={index} className="text text-sm-center" >
                                            <th  hidden scope="row">{value.iddetalles}</th>
                                            <td>{value.doc_nombres}</td>
                                            <td>{value.asi_nombre}</td>
                                            <td>{value.paralelos}</td>
                                            <td >
                                                <svg onClick={() => this.ircalificaciones(value.iddetalles, "1t", value.asi_nombre)} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#24d50f" class="bi bi-1-square" viewBox="0 0 16 16" style={{marginLeft: "5px"}}>
                                                    <path d="M9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383z"/>
                                                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
                                                </svg>
                                                <svg onClick={() => this.ircalificaciones(value.iddetalles, "2t", value.asi_nombre)} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ff7c22" class="bi bi-2-square" viewBox="0 0 16 16" style={{marginLeft: "5px"}}>
                                                    <path d="M6.646 6.24v.07H5.375v-.064c0-1.213.879-2.402 2.637-2.402 1.582 0 2.613.949 2.613 2.215 0 1.002-.6 1.667-1.287 2.43l-.096.107-1.974 2.22v.077h3.498V12H5.422v-.832l2.97-3.293c.434-.475.903-1.008.903-1.705 0-.744-.557-1.236-1.313-1.236-.843 0-1.336.615-1.336 1.306"/>
                                                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
                                                </svg>
                                                <svg onClick={() => this.ircalificaciones(value.iddetalles, "3t", value.asi_nombre)} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ff29f5" class="bi bi-3-square" viewBox="0 0 16 16" style={{marginLeft: "5px"}}>
                                                    <path d="M7.918 8.414h-.879V7.342h.838c.78 0 1.348-.522 1.342-1.237 0-.709-.563-1.195-1.348-1.195-.79 0-1.312.498-1.348 1.055H5.275c.036-1.137.95-2.115 2.625-2.121 1.594-.012 2.608.885 2.637 2.062.023 1.137-.885 1.776-1.482 1.875v.07c.703.07 1.71.64 1.734 1.917.024 1.459-1.277 2.396-2.93 2.396-1.705 0-2.707-.967-2.754-2.144H6.33c.059.597.68 1.06 1.541 1.066.973.006 1.6-.563 1.588-1.354-.006-.779-.621-1.318-1.541-1.318"/>
                                                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
                                                </svg>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <nav>
                            <center>
                                <button type="button" onClick={this.pagina_anterior} style={{ color:"white", marginRight: "10px", background:"linear-gradient(to bottom, rgba(46, 0, 255, 1), rgba(0, 251, 255, 1))" }} className="btn">Anterior</button>
                                <input type="text" readOnly style={{ marginRight: "10px", textAlign: "center", width: "120px" }} value={pagina + " de " + total_paginas} />
                                <button type="button" onClick={this.pagina_siguiente} style={{ color:"white", background:"linear-gradient(to bottom, rgba(46, 0, 255, 1), rgba(0, 251, 255, 1))"}} className="btn">Siguiente</button>
                            </center>
                        </nav>
                    </div>
                </div>
            </div>
        );
    }
}
function Navegacion(props) {
    let navigate = useNavigate();
    return <DatosDetallesDoc {...props} navigate={navigate} />
}
export default Navegacion;