import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { urlApi } from "../../services/apirest.js";
import Header from "../Header.js";
let pagina = 1;
let total_paginas = 0;
let cadena = "";
class DatosMatriculas extends React.Component {
    state = {
        registros: [],
        num_paginas: 0
    }

    pagina_siguiente = () => {
        let num_p = this.state.num_paginas[0].numpag;
        //console.log(num_p);
        if (num_p > pagina) {
            pagina += 1;
            let url = urlApi + "matriculas.php?page=" + pagina  +  "&idparalelo=" + localStorage.getItem("idpar") + "&cadena=" + cadena;
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        registros: response.data[0]
                    })
                })
        }
    }

    pagina_anterior = () => {
        if (pagina !== 1) {
            pagina -= 1;
            let url = urlApi + "matriculas.php?page=" + pagina + "&idparalelo=" + localStorage.getItem("idpar") + "&cadena=" + cadena;
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        registros: response.data[0]
                    })
                })
        }
    }

    buscarTexto = async e => {
        console.log(e.charCode);
        if (e.charCode === 13) {
            pagina = 1;
            cadena = e.target.value;
            this.componentDidMount();
        }
    }

    delete = (idmatriculas) => {
        let url = urlApi + "matriculas.php";
        let datos = {
            "token": localStorage.getItem("token"),
            "idmatriculas": idmatriculas,
            "metodo": "delete"
        }
        axios
            .post(url, datos)
            .then(response => {
                this.componentDidMount();
            })
            .catch(error => {
                console.log(error);
            })
    }

    componentDidMount = () => {
        let url = urlApi + "matriculas.php?page=" + pagina + "&idparalelo=" + localStorage.getItem("idpar") + "&cadena=" + cadena;
        axios
            .get(url)
            .then(response => {
                this.setState({
                    registros: response.data[0],
                    num_paginas: response.data[1]
                })
                total_paginas = response.data[1][0].numpag;
                if (pagina > response.data[1][0].numpag) {
                    this.pagina_anterior();
                }
            })
    }

    clicRegistro(id) {
        //console.log(id);
        localStorage.setItem("IdRegistroSeleccionado", id);
        this.props.navigate('/editarmatriculas');
    }

    nuevoRegistro = () => {
        this.props.navigate('/nuevomatriculas');
    }

    render() {
        return (
            <div>
                <Header />
                <div className="container">
                <div class="d-flex justify-content-around" style={{marginBottom: "20px"}}>

                    <div style={{marginRight: "700px"}}>
                        <button type="button" onClick={this.nuevoRegistro} style={{ marginRight: "10px", background:"linear-gradient(to bottom, rgba(0, 120, 255, 1), rgba(46, 0, 255, 1))" }} className="btn btn-primary">Nueva Matrícula</button>
                    </div>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <label style={{marginRight: "10px"}}><svg xmlns="http://www.w3.org/2000/svg" height="15" width="15" viewBox="0 0 512 512"><path fill="#000000" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg></label>
                        <input type="text" onKeyPress={this.buscarTexto}></input>
                    </div>
                    </div>
                    <div className="container">
                        <table className="table table-hover">
                            <thead>
                                <tr className="text text-sm-center">
                                    <th hidden scope="col">ID</th>
                                    <th scope="col">Estudiante</th>
                                    <th scope="col">Paralelo</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.registros.map((value, index) => {
                                    return (
                                        <tr key={index} className="text text-sm-center">
                                            <th hidden scope="row">{value.idmatriculas}</th>
                                            <td>{value.nombres}</td>
                                            <td>{value.grado}</td>
                                            <td>{value.mat_fecha}</td>
                                            <td>
                                                <svg onClick={() => this.clicRegistro(value.idmatriculas)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-pencil" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#00abfb" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" />
                                                    <path d="M13.5 6.5l4 4" />
                                                </svg>
                                                <svg onClick={() => this.delete(value.idmatriculas)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash-off" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff2825" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M3 3l18 18" />
                                                    <path d="M4 7h3m4 0h9" />
                                                    <path d="M10 11l0 6" />
                                                    <path d="M14 14l0 3" />
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l.077 -.923" />
                                                    <path d="M18.384 14.373l.616 -7.373" />
                                                    <path d="M9 5v-1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                </svg>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <nav>
                            <center>
                                <button type="button" onClick={this.pagina_anterior} style={{ color:"white", marginRight: "10px", background:"linear-gradient(to bottom, rgba(46, 0, 255, 1), rgba(0, 251, 255, 1))"}} className="btn">Anterior</button>
                                <input type="text" readOnly style={{ marginRight: "10px", textAlign: "center", width: "120px" }} value={pagina + " de " + total_paginas} />
                                <button type="button" onClick={this.pagina_siguiente} style={{ color:"white", background:"linear-gradient(to bottom, rgba(46, 0, 255, 1), rgba(0, 251, 255, 1))"}} className="btn">Siguiente</button>
                            </center>
                        </nav>
                    </div>
                </div>
            </div>
        );
    }
}
function Navegacion(props) {
    let navigate = useNavigate();
    return <DatosMatriculas {...props} navigate={navigate} />
}
export default Navegacion;