import React from "react";
import { urlApi } from "../../services/apirest.js";
import axios from "axios";
import Header from "../Header.js";
import { useNavigate } from 'react-router-dom';

class NuevoDocentes extends React.Component {
    state = {
        form: {
            "iddocentes": "",
            "doc_cedula": "",
            "doc_contrasena": "",
            "doc_nombres": "",
            "doc_telefono": "",
            "doc_direccion": "",
            "fkinstituciones": localStorage.getItem("idusuario"),
            "token": localStorage.getItem("token"),
            "metodo": "post"
        }
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    post = () => {
        const {notificacion} = this.props;
        const {validarCedula} = this.props;
        const {validarNombreApellido} = this.props;
        const {validarTelefono} = this.props;

        if (validarNombreApellido(this.state.form.doc_nombres, true)===false) {
            notificacion("Nombre no válido");
        }else if (validarCedula(this.state.form.doc_cedula, true)===false){
            notificacion("Cédula no válida");
        }else if (validarTelefono(this.state.form.doc_telefono, true)===false){
            notificacion("Solo se permiten números");
        }else {
            let url = urlApi + "docentes.php";
            let datos = this.state.form
            axios
                .post(url, datos)
                .then(response => {
                    notificacion("Los datos se han guardado correctamente");
                    this.props.navigate('/datosdocentes');
                })
                .catch(error => {
                    notificacion(error.response.data.result.error_msg);
                  //  console.log(error);
                })
        }
    }

    salir = () => {
        this.props.navigate('/datosdocentes');
    }


    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="position-absolute top-1 start-50 translate-middle-x">
                    <div className="container text-center">
                        <h2>Nuevo Docente</h2>
                    </div>
                    <div className="container">
                        <form className="form-horizontal">
                            <div className="row"> 
                                <div hidden className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="iddocentes" className="form-label">ID</label>
                                        <input className="form-control" name="iddocentes" placeholder="ID del docente" type="text" onChange={this.manejadorOnchange} readOnly/>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 ">
                                    <div className="mb-1 form-floating mb-3 ">
                                        <input className="form-control" name="doc_cedula" placeholder="Cédula" type="text" onChange={this.manejadorOnchange} maxlength="10"/>
                                        <label htmlFor="doc_cedula" className="form-label">Cédula</label>
                                    </div>
                                </div>
                                
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3 " >
                                        <input className="form-control" name="doc_contrasena" placeholder="Contraseña" type="password" onChange={this.manejadorOnchange} />
                                        <label htmlFor="doc_contrasena" className="form-label">Contraseña</label>
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3 ">
                                        <input className="form-control" name="doc_nombres" placeholder="Nombre del docente" type="text" onChange={this.manejadorOnchange} />
                                        <label htmlFor="doc_nombres" className="form-label">Nombres</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3 ">
                                        <input className="form-control" name="doc_telefono" placeholder="Número de teléfono" type="text" onChange={this.manejadorOnchange} maxlength="10"/>
                                        <label htmlFor="doc_telefono" className="form-label">Teléfono</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3 ">
                                        <input className="form-control" name="doc_direccion" placeholder="Dirección del docente" type="text" onChange={this.manejadorOnchange} />
                                        <label htmlFor="doc_direccion" className="form-label">Dirección</label>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="row">
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-primary" onClick={() => this.post()} >Guardar</button>
                                </div>
                                <div className=" col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-dark" onClick={() => this.salir()}>Cancelar</button>
                                </div>
                            </div>
                        </form>
                        {this.state.error === true &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMsg}
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <NuevoDocentes {...props} navigate={navigate} />
}

export default WithNavigate