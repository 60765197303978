import React from "react";
import { urlApi } from "../../services/apirest.js";
import axios from "axios";
import Header from "../Header.js";
import { useNavigate } from 'react-router-dom';

class NuevoInstituciones extends React.Component {
    state = {
        form: {
            "idinstituciones": "",
            "codinstitucion": "",
            "inst_nombre": "",
            "inst_direccion": "",
            "usuario": "",
            "contrasena": "",
            "token": localStorage.getItem("token"),
            "metodo": "post"
        }
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    post = () => {
        let url = urlApi + "instituciones.php";
        let datos = this.state.form
        axios
            .post(url, datos)
            .then(response => {
                alert("Los datos se han guardado exitosamente")
                this.props.navigate('/datosinstituciones');
            })
            .catch(error => {
                console.log(error);
            })
    }

    salir = () => {
        this.props.navigate('/datosinstituciones');
    }


    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="position-absolute top-1 start-50 translate-middle-x">
                    <div className="container">
                        <h2>Nueva Institución</h2>
                    </div>
                    <div className="container">
                        <form className="form-horizontal">
                            <div className="row"> 
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="idinstituciones" className="form-label">ID</label>
                                        <input className="form-control" name="idinstituciones" placeholder="ID de la institución" type="text" onChange={this.manejadorOnchange} readOnly/>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="codinstitucion" className="form-label">Código AMIE</label>
                                        <input className="form-control" name="codinstitucion" placeholder="Código" type="text" onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="inst_nombre" className="form-label">Institución</label>
                                        <input className="form-control" name="inst_nombre" placeholder="Nombre de la institución" type="text" onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="inst_direccion" className="form-label">Dirección</label>
                                        <input className="form-control" name="inst_direccion" placeholder="Dirección de la institución" type="text" onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="usuario" className="form-label">Usuario</label>
                                        <input className="form-control" name="usuario" placeholder="Usuario" type="text" onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="contrasena" className="form-label">Contraseña</label>
                                        <input className="form-control" name="contrasena" placeholder="Contraseña" type="password" onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary" onClick={() => this.post()} style={{ marginRight: "10px" }}>Guardar</button>
                            
                            <button type="button" className="btn btn-dark" onClick={() => this.salir()}>Cancelar</button>
                        </form>
                        {this.state.error === true &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMsg}
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <NuevoInstituciones {...props} navigate={navigate} />
}

export default WithNavigate