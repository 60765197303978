import React from "react";
import { urlApi } from "../../services/apirest.js";
import axios from "axios";
import Header from "../Header.js";
import { useNavigate } from 'react-router-dom';

class EditarEstudiantes extends React.Component {
    state = {
        form: {
            "idestudiantes": "",
            "est_cedula": "",
            // "est_contrasena": "",
            "est_nombres": "",
            "est_apellidos": "",
            "est_fechanacimiento": "",
            "est_sexo": "",
            "est_direccion": "",
            "fkinstituciones": localStorage.getItem("idusuario"),
            "token": localStorage.getItem("token"),
            "metodo": "put"
        }
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    componentDidMount = () => {
        let idRegistro = localStorage.getItem("IdRegistroSeleccionado");
        let url = urlApi + "estudiantes.php?id=" + idRegistro;
        axios
            .get(url)
            .then(response => {
                this.setState({
                    form: {
                        idestudiantes: response.data[0].idestudiantes,
                        est_cedula: response.data[0].est_cedula,
                        // est_contrasena: response.data[0].est_contrasena,
                        est_nombres: response.data[0].est_nombres,
                        est_apellidos: response.data[0].est_apellidos,
                        est_fechanacimiento: response.data[0].est_fechanacimiento,
                        est_sexo: response.data[0].est_sexo,
                        est_direccion: response.data[0].est_direccion,
                        fkinstituciones: localStorage.getItem("idusuario"),
                        token: localStorage.getItem("token"),
                        metodo: "put"
                    }
                    
                })
                
            })
            .catch(error => {
                console.log("Error de conexion");
            })
    }

    put = () => {
        const {notificacion} = this.props;
        const {validarCedula} = this.props;
        const {validarNombreApellido} = this.props;
        const {validarFecha} = this.props;

        if (validarNombreApellido(this.state.form.est_nombres, true)===false) {
            notificacion("Nombre no válido");
        }else if (validarNombreApellido(this.state.form.est_apellidos, true)===false){
            notificacion("Apellido no válido");
        }else if (validarCedula(this.state.form.est_cedula, true)===false){
            notificacion("Cédula no válida");
        }
        /* else if (validarFecha(this.state.form.est_fechanacimiento, true)===false){
            notificacion("Fecha no válida");
        } */else {
            let url = urlApi + "estudiantes.php";
            let datos = this.state.form
            axios
                .post(url, datos)
                .then(response => {
                    this.props.navigate('/datosestudiantes');
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }

    salir = () => {
        this.props.navigate('/datosestudiantes');
    }


    render() {
        const formData = this.state.form;
        return (
            <React.Fragment>
                <Header />
                <div className="position-absolute top-1 start-50 translate-middle-x">
                    <div className="container text-center">
                        <h2>Editar Estudiantes</h2>
                    </div>
                    <div className="container">
                        <form className="form-horizontal">
                            <div className="row"> 
                                <div hidden className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="idestudiantes" className="form-label">ID</label>
                                        <input className="form-control" name="idestudiantes" placeholder="ID del estudiante" type="text" defaultValue={formData.idestudiantes} onChange={this.manejadorOnchange} readOnly/>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="est_cedula" placeholder="Cédula" type="text" defaultValue={formData.est_cedula} onChange={this.manejadorOnchange} maxlength="10"/>
                                        <label htmlFor="est_cedula" className="form-label">Cédula</label>
                                    </div>
                                </div>
                                
                                <div hidden className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="est_contrasena" placeholder="Contraseña" type="password" defaultValue={formData.est_contrasena} onChange={this.manejadorOnchange}/>
                                        <label htmlFor="est_contrasena" className="form-label">Contraseña</label>
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="est_nombres" placeholder="Nombres del estudiante" type="text" defaultValue={formData.est_nombres} onChange={this.manejadorOnchange} />
                                        <label htmlFor="est_nombres" className="form-label">Nombres</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="est_apellidos" placeholder="Apellidos del estudiante" type="text" defaultValue={formData.est_apellidos} onChange={this.manejadorOnchange} />
                                        <label htmlFor="est_apellidos" className="form-label">Apellidos</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="est_fechanacimiento" placeholder="Fecha de nacimiento" type="date" defaultValue={formData.est_fechanacimiento} onChange={this.manejadorOnchange} />
                                        <label htmlFor="est_fechanacimiento" className="form-label">Fecha Nacimiento</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <select class="form-select" name="est_sexo" defaultValue={formData.est_sexo} onChange={this.manejadorOnchange}>
                                            <option selected></option>
                                            <option value="1">Masculino</option>
                                            <option value="2">Femenino</option>
                                        </select>
                                        <label htmlFor="est_sexo" className="form-label">Sexo</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="est_direccion" placeholder="Dirección" type="text" defaultValue={formData.est_direccion} onChange={this.manejadorOnchange} />
                                        <label htmlFor="est_direccion" className="form-label">Dirección</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-primary" onClick={() => this.put()} >Editar</button>
                                </div>
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-dark" onClick={() => this.salir()}>Salir</button>
                                </div>
                            </div>
                        </form>
                        {this.state.error === true &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMsg}
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <EditarEstudiantes {...props} navigate={navigate} />
}

export default WithNavigate