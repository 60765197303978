import React from "react";
import { urlApi } from "../../services/apirest.js";
import axios from "axios";
import Header from "../Header.js";
import { useNavigate } from 'react-router-dom';

class NuevoAsignaturas extends React.Component {
    state = {
        form: {
            "idasignaturas": "",
            "asi_nombre": "",
            "asi_descripcion": "",
            "fkinstituciones": localStorage.getItem("idusuario"),
            "token": localStorage.getItem("token"),
            "metodo": "post"
        }
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    post = () => {
        const {notificacion} = this.props;
        const {validarLetrasEspacio} = this.props;

        if (validarLetrasEspacio(this.state.form.asi_nombre, true)===false) {
            notificacion("Solo se permiten letras no válido");
        }else {
            let url = urlApi + "asignaturas.php";
            let datos = this.state.form
            axios
                .post(url, datos)
                .then(response => {
                    notificacion("Los datos se han guardado correctamente");
                    this.props.navigate('/datosasignaturas');
                })
                .catch(error => {
                    console.log(error);
                })
        }  
    }
    salir = () => {
        this.props.navigate('/datosasignaturas');
    }
    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="position-absolute top-1 start-50 translate-middle-x">
                    <div className="container text-center">
                        <h2>Nueva Asignatura</h2>
                    </div>
                    <div className="container">
                        <form className="form-horizontal">
                            <div className="row"> 
                                <div hidden className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="idasignaturas" className="form-label">ID</label>
                                        <input className="form-control" name="idasignaturas" placeholder="ID de la asignatura" type="text" onChange={this.manejadorOnchange} readOnly/>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="asi_nombre" placeholder="Nombre" type="text" onChange={this.manejadorOnchange} />
                                        <label htmlFor="nombre" className="form-label">Nombre Asignatura</label>
                                    </div>
                                </div>
                                
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <textarea className="form-control" name="asi_descripcion" placeholder="Descripción" type="text" onChange={this.manejadorOnchange}></textarea>
                                        <label htmlFor="asi_descripcion" className="form-label">Descripción</label>
                                    </div>
                                </div>
                                <div className="w-100"></div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-primary" onClick={() => this.post()} >Guardar</button>
                                </div>
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-dark" onClick={() => this.salir()}>Cancelar</button>
                                </div>
                            </div>
                        </form>
                        {this.state.error === true &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMsg}
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <NuevoAsignaturas {...props} navigate={navigate} />
}

export default WithNavigate