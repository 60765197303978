import React from "react";
import { urlApi } from "../../services/apirest.js";
import axios from "axios";
import Header from "../Header.js";
import { useNavigate } from 'react-router-dom';

class EditarParalelos extends React.Component {
    state = {
        form: {
            "idparalelos": "",
            "par_numero": "",
            "par_grado": "",
            "par_paralelo": "",
            "par_jornada": "",
            "fkinstituciones": localStorage.getItem("idusuario"),
            "token": localStorage.getItem("token"),
            "metodo": "put"
        }
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    componentDidMount = () => {
        let idRegistro = localStorage.getItem("IdRegistroSeleccionado");
        let url = urlApi + "paralelos.php?id=" + idRegistro;
        axios
            .get(url)
            .then(response => {
                this.setState({
                    form: {
                        idparalelos: response.data[0].idparalelos,
                        par_numero: response.data[0].par_numero,
                        par_grado: response.data[0].par_grado,
                        par_paralelo: response.data[0].par_paralelo,
                        par_jornada: response.data[0].par_jornada,
                        fkinstituciones: localStorage.getItem("idusuario"),
                        token: localStorage.getItem("token"),
                        metodo: "put"
                    }
                    
                })
                
            })
            .catch(error => {
                console.log("Error de conexion");
            })
    }

    put = () => {
        let url = urlApi + "paralelos.php";
        let datos = this.state.form
        axios
            .post(url, datos)
            .then(response => {
                this.props.navigate('/datosparalelos');
            })
            .catch(error => {
                console.log(error);
            })
    }

    salir = () => {
        this.props.navigate('/datosparalelos');
    }


    render() {
        const formData = this.state.form;
        return (
            <React.Fragment>
                <Header />
                <div className="position-absolute top-1 start-50 translate-middle-x">
                    <div className="container text-center">
                        <h2>Editar Paralelo</h2>
                    </div>
                    <div className="container">
                        <form className="form-horizontal">
                            <div className="row"> 
                                {<div hidden className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="idparalelos" className="form-label">ID</label>
                                        <input className="form-control" name="idparalelos" placeholder="ID del paralelo" type="text" defaultValue={formData.idparalelos} onChange={this.manejadorOnchange} readOnly/>
                                    </div>
                                </div>}
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="par_numero" placeholder="Número" type="text" defaultValue={formData.par_numero} onChange={this.manejadorOnchange} />
                                        <label htmlFor="par_numero" className="form-label">Número</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="par_grado" placeholder="Grado" type="text" defaultValue={formData.par_grado} onChange={this.manejadorOnchange} />
                                        <label htmlFor="par_grado" className="form-label">Grado</label>
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="par_paralelo" placeholder="Paralelo" type="text" defaultValue={formData.par_paralelo} onChange={this.manejadorOnchange} />
                                        <label htmlFor="par_paralelo" className="form-label">Paralelo</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="par_jornada" placeholder="Jornada" type="text" defaultValue={formData.par_jornada} onChange={this.manejadorOnchange} />
                                        <label htmlFor="par_jornada" className="form-label">Jornada</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-primary" onClick={() => this.put()} >Editar</button>
                                </div>
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-dark" onClick={() => this.salir()}>Salir</button>
                                </div>
                            </div>
                        </form>
                        {this.state.error === true &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMsg}
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <EditarParalelos {...props} navigate={navigate} />
}

export default WithNavigate