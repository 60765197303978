import React from "react";
import { urlApi } from "../../services/apirest.js";
import axios from "axios";
import Header from "../Header.js";
import { useNavigate } from 'react-router-dom';

class NuevoParalelos extends React.Component {
    state = {
        form: {
            "idparalelos": "",
            "par_numero": "",
            "par_grado": "",
            "par_paralelo": "",
            "par_jornada": "",
            "fkinstituciones": localStorage.getItem("idusuario"),
            "token": localStorage.getItem("token"),
            "metodo": "post"
        }
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);


    }

    manejadorOnchangec = async e => {
        
        this.setState({
            form: {
                "par_grado": document.getElementsByName("par_grado").value
            }
        })
        console.log(this.state.form); 
        var grado =  e.target.selectedOptions[0].getAttribute("data-grado")
        document.getElementById("grado").value = grado;
    }

    post = () => {
        const {notificacion} = this.props;
        let url = urlApi + "paralelos.php";
        let datos = this.state.form
        axios
            .post(url, datos)
            .then(response => {
                notificacion("Los datos se han guardado exitosamente");
                this.props.navigate('/datosparalelos');
            })
            .catch(error => {
                console.log(error);
            })
    }

    salir = () => {
        this.props.navigate('/datosparalelos');
    }


    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="position-absolute top-1 start-50 translate-middle-x">
                    <div className="container text-center">
                        <h2>Nuevo Paralelo</h2>
                    </div>
                    <div className="container">
                        <form className="form-horizontal">
                            <div className="row"> 
                                <div hidden className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="idparalelos" className="form-label">ID</label>
                                        <input className="form-control" name="idparalelos" placeholder="ID del paralelo" type="text" onChange={this.manejadorOnchange} readOnly/>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <select class="form-select" name="par_numero" onChange={this.manejadorOnchange} onChangeCapture={this.manejadorOnchangec}>
                                            <option disabled selected></option>
                                            <option data-grado="Segundo" value="2">2</option>
                                            <option data-grado="Tercero" value="3">3</option>
                                            <option data-grado="Cuarto" value="4">4</option>
                                        </select>
                                        <label htmlFor="par_numero" className="form-label">Grado</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input id="grado"  className="form-control" name="par_grado" type="text" onChange={this.manejadorOnchange} />
                                        <label htmlFor="par_grado" className="form-label">Grado</label>
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <select class="form-select" name="par_paralelo" onChange={this.manejadorOnchange}>
                                            <option selected></option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                            <option value="D">D</option>
                                            <option value="E">E</option>
                                            <option value="F">F</option>
                                        </select>
                                        <label htmlFor="par_paralelo" className="form-label">Paralelo</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <select class="form-select" name="par_jornada" onChange={this.manejadorOnchange}>
                                            <option selected></option>
                                            <option value="Matutina">Matutina</option>
                                            <option value="Vespertina">Vespertina</option>
                                        </select>
                                        <label htmlFor="par_jornada" className="form-label">Jornada</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-primary" onClick={() => this.post()} >Guardar</button>
                                </div>
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-dark" onClick={() => this.salir()}>Cancelar</button>
                                </div>
                            </div>
                        </form>
                        {this.state.error === true &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMsg}
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <NuevoParalelos {...props} navigate={navigate} />
}

export default WithNavigate