import React, { useState } from "react";
import { urlApi } from "../../services/apirest.js";
import axios from "axios";
import Header from "../Header.js";
import Datos_Estudiantes from "../estudiantes/Datos_Estudiantes.jsx";
import { useNavigate } from 'react-router-dom';


class NuevoMatriculas extends React.Component {
    
    abrirModal = () => {
        this.setState({ mostrarModal: true });
    }

    cerrarModal = () => {
        this.setState({ mostrarModal: false });
    }
    
    state = {
        form: {
            "idmatriculas": "",
            "mat_fecha": "",
            "fkestudiantes": "",
            "fkparalelos": localStorage.getItem("idpar"),
            "token": localStorage.getItem("token"),
            "metodo": "post"
        },
        mostrarModal: false,
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    post = () => {
        const {notificacion} = this.props;
        let url = urlApi + "matriculas.php";
        let datos = this.state.form
        axios
            .post(url, datos)
            .then(response => {
                notificacion("Los datos se han guardado correctamente");
                this.props.navigate('/datosmatriculas');
            })
            .catch(error => {
                notificacion(error.response.data.result.error_msg);
            })
    }

    salir = () => {
        this.props.navigate('/datosmatriculas');
    }

    componentDidMount() {
        const {EditarVariable} = this.props;
        EditarVariable("", "");
    }


    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="position-absolute top-1 start-50 translate-middle-x">
                    <div className="container">
                        <h2>Nueva Matrícula</h2>
                    </div>
                    <div className="container">
                        <form className="form-horizontal">
                            <div  className="row"> 
                                <div hidden className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="idmatriculas" className="form-label">ID</label>
                                        <input className="form-control" name="idmatriculas" placeholder="ID de la matrícula" type="text" onChange={this.manejadorOnchange} readOnly/>
                                    </div>
                                    
                                </div>
                                < div className="w-100"></div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        
                                        <input className="form-control" name="mat_fecha" type="date" onChange={this.manejadorOnchange} />
                                        <label htmlFor="mat_fecha" className="form-label">Fecha Matrícula</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        
                                        <input className="form-control" name="fkestudiantes" placeholder="ID Estudiante" type="text" value={this.props.datoForaneo} onClick={this.abrirModal}/>
                                        <label htmlFor="fkestudiantes" className="form-label">Estudiante</label>
                                    </div>
                                </div>
                                {/* <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="fkparalelos" className="form-label">Paralelo</label>
                                        <input className="form-control" name="fkparalelos" placeholder="ID Paralelo" type="text" onChange={this.manejadorOnchange} />
                                    </div>
                                </div> */}
                            </div>

                           <div className="row" style={{marginTop:"15px"}}>
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-primary" onClick={() => this.post()} >Guardar</button>
                                </div>
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-dark" onClick={() => this.salir()}>Cancelar</button>
                                </div>
                            </div>
                        </form>
                        {this.state.error === true &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMsg}
                            </div>
                        }
                    </div>
                </div>
                {this.state.mostrarModal &&
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={this.cerrarModal}>&times;</span>
                            <Datos_Estudiantes EditarVariable={this.props.EditarVariable} cerrarModal={this.cerrarModal} manejadorOnchange={this.manejadorOnchange} />
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }

}

function WithNavigate(props) {
    const [showModal, setShowModal] = useState(false);
    let navigate = useNavigate();
    return <NuevoMatriculas {...props} navigate={navigate} showModal={showModal} setShowModal={setShowModal}/>
    
}

export default WithNavigate