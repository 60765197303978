import React from "react";
import "./assets/CSS/App.css";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login.jsx";
import Datos_Asignaturas from "./components/asignaturas/Datos_Asignaturas.jsx";
import Editar_Asignaturas from "./components/asignaturas/Editar_Asignaturas.jsx";
import Nuevo_Asignaturas from "./components/asignaturas/Nuevo_Asignaturas.jsx";

import Datos_Instituciones from "./components/instituciones/Datos_Instituciones.jsx";
import Editar_Instituciones from "./components/instituciones/Editar_Instituciones.jsx";
import Nuevo_Instituciones from "./components/instituciones/Nuevo_Instituciones.jsx";

import Datos_Docentes from "./components/docentes/Datos_Docentes.jsx";
import Editar_Docentes from "./components/docentes/Editar_Docentes.jsx";
import Nuevo_Docentes from "./components/docentes/Nuevo_Docentes.jsx";

import Datos_Estudiantes from "./components/estudiantes/Datos_Estudiantes.jsx";
import Nuevo_Estudiantes from "./components/estudiantes/Nuevo_Estudiantes.jsx";
import Editar_Estudiantes from "./components/estudiantes/Editar_Estudiantes.jsx";

import Datos_Paralelos from "./components/paralelos/Datos_Paralelos.jsx";
import Nuevo_Paralelos from "./components/paralelos/Nuevo_Paralelos.jsx";
import Editar_Paralelos from "./components/paralelos/Editar_Paralelos.jsx";

import Datos_Matriculas from "./components/matriculas/Datos_Matriculas.jsx";
import Nuevo_Matriculas from "./components/matriculas/Nuevo_Matriculas.jsx";
import Editar_Matriculas from "./components/matriculas/Editar_Matriculas.jsx";

import Datos_Detalles from "./components/detalles/Datos_Detalles.jsx";
import Nuevo_Detalles from "./components/detalles/Nuevo_Detalles.jsx";
import Editar_Detalles from "./components/detalles/Editar_Detalles.jsx";
import Datos_Detalles_Doc from "./components/detalles_doc/Datos_Detalles_Doc.jsx"


import Datos_Calificaciones from "./components/calificaciones/Datos_Calificaciones.jsx";
import Datos_Calificaciones_Doc from "./components/calificaciones_doc/Datos_Calificaciones_Doc.jsx";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      NoModal: true,
      idForaneo: "0",
      datoForaneo: "0",

      idForaneo2: "0",
      datoForaneo2: "0",

      idForaneo3: "0",
      datoForaneo3: "0"
    };
    this.EditarVariable = this.EditarVariable.bind(this);
    this.EditarVariable2 = this.EditarVariable2.bind(this);
    this.EditarVariable3 = this.EditarVariable3.bind(this);
  }
  EditarVariable(valorid, valorDato) {
    this.setState({
      idForaneo: valorid,
      datoForaneo: valorDato
    });
  }


  EditarVariable2(valorid, valorDato) {
    this.setState({
      idForaneo2: valorid,
      datoForaneo2: valorDato
    });
  }

  EditarVariable3(valorid, valorDato) {
    this.setState({
      idForaneo3: valorid,
      datoForaneo3: valorDato
    });
  }


  validarNombreApellido = (cadena, obligatorio) => {
    if (obligatorio === true && cadena === undefined) {
      return false;
    } else {
      let ExpRegNomApe = /^[A-ZÑa-zñáéíóúÁÉÍÓÚ'° ]+$/;
      if (ExpRegNomApe.test(cadena)) {
        return true;
      } else {
        return false;
      }
    }
  };

  validarTelefono = (cadena, obligatorio) => {
    if (obligatorio === true && cadena === undefined) {
      return false;
    } else {
      let ExpRegTelefono = /^[0-9]+$/;
      if (ExpRegTelefono.test(cadena)) {
        return true;
      } else {
        return false;
      }
    }
  };
  /* validarFecha = (cadena, obligatorio) => {
    if (obligatorio === true && !cadena) {
      return false;
    } else {
      let ExpRegFecha = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
      if (ExpRegFecha.test(cadena)) {
        return true;
      } else {
        return false;
      }
    }
  }; */

  validarFechaNacimiento = (fecha) => {
    const fechaIngresada = new Date(fecha);
    const year = fechaIngresada.getFullYear();

    return year >= 2016 && year <= 2018;
  };
  

  validarLetrasEspacio = (cadena, obligatorio) => {
    if (obligatorio === true && cadena === undefined) {
      return false;
    } else {
      let ExpRegLetrasEspacio = /^[ a-zA-ZñÑáéíóúÁÉÍÓÚ]+$/;
      if (ExpRegLetrasEspacio.test(cadena)) {
        return true;
      } else {
        return false;
      }
    }
  };

  cuant_a_cual = (calificac) => {
    let calificacion = parseFloat(calificac);
    if (calificacion >= 9) {
      return "A";
    } else if (calificacion >= 7) {
      return "EP";
    } else if (calificacion >= 4.01) {
      return "I";
    } else {
      return "NE"
    }
  };

  notificacion = (mensaje) => {
    const parrafo = document.createElement("P"); //CREA UN ELEMENTO P
    parrafo.textContent = mensaje; //AGREGA EL TEXTO AL PARRAFO
    parrafo.classList.add("alert"); //DARLE UNA CLASE AL PARRAFO
    parrafo.classList.add("alert-danger"); //LA CLASE AL PARRAFO
    document.querySelector(".notificacion").appendChild(parrafo);
    setTimeout(() => {
      parrafo.remove();
    }, 3500);
  };

  validarCedula = (array, obligatorio = false) => {
    if (obligatorio === false && array === "") {
      return true;
    } else {
      let total;
      let mult;
      let final;
      let num = array.length;
      if (num === 10) {
        let dos_digitos = array.substring(0, 2);
        //console.log(dos_digitos);
        dos_digitos = parseInt(dos_digitos);
        //console.log(dos_digitos);
        if (dos_digitos >= 1 && dos_digitos <= 24) {
          //Bloque validacion
          total = 0;
          let digito = array[9] * 1;
          for (let i = 0; i < num - 1; i++) {
            mult = 0;
            if (i % 2 !== 0) {
              total = total + array[i] * 1;
            } else {
              mult = array[i] * 2;
              if (mult > 9) total = total + (mult - 9);
              else total = total + mult;
            }
          }
          let decena = total / 10;
          decena = Math.floor(decena);
          decena = (decena + 1) * 10;
          final = decena - total;
          if ((final === 10 && digito === 0) || final === digito) {
            return true;
          } else {
            return false;
          }
          //Fin bloque validacion
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="notificacion"></div>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/datosinstituciones"
              element={<Datos_Instituciones />}
            />
            <Route
              path="/nuevoinstituciones"
              element={<Nuevo_Instituciones />}
            />
            <Route
              path="/editarinstituciones"
              element={<Editar_Instituciones />}
            />

            <Route path="/datosasignaturas" element={<Datos_Asignaturas NoModal={this.state.NoModal} />} />
            <Route
              path="/nuevoasignaturas"
              element={
                <Nuevo_Asignaturas
                  notificacion={this.notificacion}
                  validarLetrasEspacio={this.validarLetrasEspacio}

                />
              }
            />
            <Route
              path="/editarasignaturas"
              element={
                <Editar_Asignaturas
                  notificacion={this.notificacion}
                  validarLetrasEspacio={this.validarLetrasEspacio}
                />
              }
            />

            <Route path="/datosdocentes" element={<Datos_Docentes NoModal={this.state.NoModal} />} />
            <Route
              path="/nuevodocentes"
              element={
                <Nuevo_Docentes
                  notificacion={this.notificacion}
                  validarCedula={this.validarCedula}
                  validarNombreApellido={this.validarNombreApellido}
                  validarTelefono={this.validarTelefono}
                />
              }
            />
            <Route
              path="/editardocentes"
              element={
                <Editar_Docentes
                  notificacion={this.notificacion}
                  validarCedula={this.validarCedula}
                  validarNombreApellido={this.validarNombreApellido}
                  validarTelefono={this.validarTelefono}
                />
              }
            />

            <Route path="/datosestudiantes" element={<Datos_Estudiantes NoModal={this.state.NoModal} />} />
            <Route
              path="/nuevoestudiantes"
              element={
                <Nuevo_Estudiantes
                  notificacion={this.notificacion}
                  validarCedula={this.validarCedula}
                  validarNombreApellido={this.validarNombreApellido}
                  validarFechaNacimiento={this.validarFechaNacimiento}
                />
              }
            />
            <Route
              path="/editarestudiantes"
              element={
                <Editar_Estudiantes
                  notificacion={this.notificacion}
                  validarCedula={this.validarCedula}
                  validarNombreApellido={this.validarNombreApellido}
                  validarFecha={this.validarFecha}
                />
              }
            />

            <Route path="/datosparalelos" element={<Datos_Paralelos NoModal={this.state.NoModal} />} />
            <Route path="/nuevoparalelos" element={<Nuevo_Paralelos notificacion={this.notificacion} />} />
            <Route path="/editarparalelos" element={<Editar_Paralelos />} />

            <Route path="/datosmatriculas" element={<Datos_Matriculas NoModal={this.state.NoModal} />} />
            <Route path="/nuevomatriculas" element={
              <Nuevo_Matriculas
                notificacion={this.notificacion}
                EditarVariable={this.EditarVariable}
                idForaneo={this.state.idForaneo}
                datoForaneo={this.state.datoForaneo}
            />} />
            <Route path="/editarmatriculas" element={<Editar_Matriculas
              EditarVariable={this.EditarVariable}
              idForaneo={this.state.idForaneo}
              datoForaneo={this.state.datoForaneo}
            />} />

            <Route path="/datosdetalles" element={<Datos_Detalles />} />
            <Route
              path="/nuevodetalles"
              element={
                <Nuevo_Detalles
                  notificacion={this.notificacion}
                  EditarVariable={this.EditarVariable}
                  idForaneo={this.state.idForaneo}
                  datoForaneo={this.state.datoForaneo}

                  EditarVariable2={this.EditarVariable2}
                  idForaneo2={this.state.idForaneo2}
                  datoForaneo2={this.state.datoForaneo2}

                  EditarVariable3={this.EditarVariable3}
                  idForaneo3={this.state.idForaneo3}
                  datoForaneo3={this.state.datoForaneo3}
                />
              }
            />
            <Route path="/editardetalles" element={<Editar_Detalles
              EditarVariable={this.EditarVariable}
              idForaneo={this.state.idForaneo}
              datoForaneo={this.state.datoForaneo} />} />

            <Route path="/datosdetallesdoc" element={<Datos_Detalles_Doc />} />

            <Route
              path="/datoscalificaciones"
              element={<Datos_Calificaciones cuant_a_cual={this.cuant_a_cual} notificacion={this.notificacion} />}
            />
            <Route
              path="/datoscalificacionesdoc"
              element={<Datos_Calificaciones_Doc cuant_a_cual={this.cuant_a_cual} notificacion={this.notificacion} />}
            />

          </Routes>

        </Router>
      </React.Fragment>
    );
  }
}

export default App;
