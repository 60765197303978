import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { urlApi } from "../../services/apirest.js";
import Header from "../Header.js";
let pagina = 1;
let total_paginas = 0;
let cadena = "";
class DatosInstituciones extends React.Component {
    state = {
        registros: [],
        num_paginas: 0
    }

    pagina_siguiente = () => {
        let num_p = this.state.num_paginas[0].numpag;
        //console.log(num_p);
        if (num_p > pagina) {
            pagina += 1;
            let url = urlApi + "instituciones.php?page=" + pagina + "&cadena=" + cadena;
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        registros: response.data[0]
                    })
                })
        }
    }

    pagina_anterior = () => {
        if (pagina !== 1) {
            pagina -= 1;
            let url = urlApi + "instituciones.php?page=" + pagina + "&cadena=" + cadena;
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        registros: response.data[0]
                    })
                })
        }
    }

    buscarTexto = async e => {
        console.log(e.charCode);
        if (e.charCode === 13) {
            pagina = 1;
            cadena = e.target.value;
            this.componentDidMount();
        }
    }

    delete = (idinstituciones) => {
        let url = urlApi + "instituciones.php";
        let datos = {
            "token": localStorage.getItem("token"),
            "idinstituciones": idinstituciones,
            "metodo": "delete"
        }
        axios
            .post(url, datos)
            .then(response => {
                this.componentDidMount();
            })
            .catch(error => {
                console.log(error);
            })
    }

    componentDidMount = () => {
        let url = urlApi + "instituciones.php?page=" + pagina + "&cadena=" + cadena;
        axios
            .get(url)
            .then(response => {
                this.setState({
                    registros: response.data[0],
                    num_paginas: response.data[1]
                })
                total_paginas = response.data[1][0].numpag;
                if (pagina > response.data[1][0].numpag) {
                    this.pagina_anterior();
                }
            })
    }

    clicRegistro(id) {
        //console.log(id);
        localStorage.setItem("IdRegistroSeleccionado", id);
        this.props.navigate('/editarinstituciones');
    }

    nuevoRegistro = () => {
        this.props.navigate('/nuevoinstituciones');
    }

    render() {
        return (
            <div>
                <Header />
                <div className="container">
                    <button type="button" onClick={this.nuevoRegistro} style={{ marginRight: "10px" }} className="btn btn-warning">Nueva Institución</button>
                    <input type="text" onKeyPress={this.buscarTexto}></input>
                    <div className="container">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Código</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Dirección</th>
                                    <th scope="col">Usuario</th>
                                    <th scope="col">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.registros.map((value, index) => {
                                    return (
                                        <tr key={index} >
                                            <th scope="row">{value.idinstituciones}</th>
                                            <td>{value.codinstitucion}</td>
                                            <td>{value.inst_nombre}</td>
                                            <td>{value.inst_direccion}</td>
                                            <td>{value.usuario}</td>
                                            <td>
                                                <svg onClick={() => this.clicRegistro(value.idinstituciones)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-pencil" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#00abfb" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" />
                                                    <path d="M13.5 6.5l4 4" />
                                                </svg>
                                                <svg onClick={() => this.delete(value.idinstituciones)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash-off" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff2825" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M3 3l18 18" />
                                                    <path d="M4 7h3m4 0h9" />
                                                    <path d="M10 11l0 6" />
                                                    <path d="M14 14l0 3" />
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l.077 -.923" />
                                                    <path d="M18.384 14.373l.616 -7.373" />
                                                    <path d="M9 5v-1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                </svg>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <nav>
                            <center>
                                <button type="button" onClick={this.pagina_anterior} style={{ marginRight: "10px" }} className="btn btn-success">Anterior</button>
                                <input type="text" readOnly style={{ marginRight: "10px", textAlign: "center", width: "120px" }} value={pagina + " de " + total_paginas} />
                                <button type="button" onClick={this.pagina_siguiente} className="btn btn-success">Siguiente</button>
                            </center>
                        </nav>
                    </div>
                </div>
            </div>
        );
    }
}
function Navegacion(props) {
    let navigate = useNavigate();
    return <DatosInstituciones {...props} navigate={navigate} />
}
export default Navegacion;