import React from "react";
import { urlApi } from "../../services/apirest.js";
import axios from "axios";
import Header from "../Header.js";
import { useNavigate } from 'react-router-dom';

class EditarInstituciones extends React.Component {
    state = {
        form: {
            "idinstituciones": "",
            "codinstitucion": "",
            "inst_nombre": "",
            "inst_direccion": "",
            "usuario": "",
            "token": localStorage.getItem("token"),
            "metodo": "put"
        }
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    componentDidMount = () => {
        let idRegistro = localStorage.getItem("IdRegistroSeleccionado");
        let url = urlApi + "instituciones.php?id=" + idRegistro;
        axios
            .get(url)
            .then(response => {
                this.setState({
                    form: {
                        idinstituciones: response.data[0].idinstituciones,
                        codinstitucion: response.data[0].codinstitucion,
                        inst_nombre: response.data[0].inst_nombre,
                        inst_direccion: response.data[0].inst_direccion,
                        usuario: response.data[0].usuario,
                        token: localStorage.getItem("token"),
                        metodo: "put"
                    }
                    
                })
                
            })
            .catch(error => {
                console.log("Error de conexion");
            })
    }

    put = () => {
        let url = urlApi + "instituciones.php";
        let datos = this.state.form
        axios
            .post(url, datos)
            .then(response => {
                this.props.navigate('/datosinstituciones');
            })
            .catch(error => {
                console.log(error);
            })
    }

    salir = () => {
        this.props.navigate('/datosinstituciones');
    }


    render() {
        const formData = this.state.form;
        return (
            <React.Fragment>
                <Header />
                <div className="position-absolute top-1 start-50 translate-middle-x">
                    <div className="container">
                        <h2>Editar Instituciones</h2>
                    </div>
                    <div className="container">
                        <form className="form-horizontal">
                            <div className="row"> 
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="idinstituciones" className="form-label">ID</label>
                                        <input className="form-control" name="idinstituciones" placeholder="ID de la institución" type="text" defaultValue={formData.idinstituciones} onChange={this.manejadorOnchange} readOnly/>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="codinstitucion" className="form-label">Código AMIE</label>
                                        <input className="form-control" name="codinstitucion" placeholder="Código" type="text" defaultValue={formData.codinstitucion} onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="inst_nombre" className="form-label">Institución</label>
                                        <input className="form-control" name="inst_nombre" placeholder="Nombre de la institución" type="text" defaultValue={formData.inst_nombre} onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="inst_direccion" className="form-label">Dirección</label>
                                        <input className="form-control" name="inst_direccion" placeholder="Dirección" type="text" defaultValue={formData.inst_direccion} onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="usuario" className="form-label">Usuario</label>
                                        <input className="form-control" name="usuario" placeholder="Usuario" type="text" defaultValue={formData.usuario} onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary" onClick={() => this.put()} style={{ marginRight: "10px" }}>Editar</button>
                            
                            <button type="button" className="btn btn-dark" onClick={() => this.salir()}>Salir</button>
                        </form>
                        {this.state.error === true &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMsg}
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <EditarInstituciones {...props} navigate={navigate} />
}

export default WithNavigate