import React from "react";
import { urlApi } from "../../services/apirest.js";
import axios from "axios";
import Header from "../Header.js";
import { useNavigate } from 'react-router-dom';

class NuevoEstudiantes extends React.Component {
    state = {
        form: {
            "idestudiantes": "",
            "est_cedula": "",
            "est_contrasena": "",
            "est_nombres": "",
            "est_apellidos": "",
            "est_fechanacimiento": "",
            "est_sexo": "",
            "est_direccion": "",
            "fkinstituciones": localStorage.getItem("idusuario"),
            "token": localStorage.getItem("token"),
            "metodo": "post"
        }
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    post = () => {
        const {notificacion} = this.props;
        const {validarCedula} = this.props;
        const {validarNombreApellido} = this.props;
        const {validarFechaNacimiento} = this.props;

        if (validarNombreApellido(this.state.form.est_nombres, true)===false) {
            notificacion("Nombre no válido");
        }else if (validarNombreApellido(this.state.form.est_apellidos, true)===false){
            notificacion("Apellido no válido");
        }else if (validarCedula(this.state.form.est_cedula, true)===false){
            notificacion("Cédula no válida");
        } else if (validarFechaNacimiento(this.state.form.est_fechanacimiento, true) === false) {
            notificacion("Fecha de nacimiento no válida. Debe estar entre 2016 y 2018.");
        }else {
            let url = urlApi + "estudiantes.php";
            let datos = this.state.form
            axios
                .post(url, datos)
                .then(response => {
                    notificacion("Los datos se han guardado correctamente");
                    this.props.navigate('/datosestudiantes');
                })
                .catch(error => {
                    notificacion(error.response.data.result.error_msg);
                  //  console.log();
                })
        }
    }

    salir = () => {
        this.props.navigate('/datosestudiantes');
    }


    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="position-absolute top-1 start-50 translate-middle-x">
                    <div className="container text-center">
                        <h2>Nuevo Estudiante</h2>
                    </div>
                    <div className="container">
                        <form className="form-horizontal">
                            <div className="row"> 
                                <div hidden className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="idestudiantes" className="form-label">ID</label>
                                        <input className="form-control" name="idestudiantes" placeholder="ID del estudiante" type="text" onChange={this.manejadorOnchange} readOnly/>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="est_cedula" placeholder="Cédula" type="text" onChange={this.manejadorOnchange} maxlength="10"/>
                                        <label htmlFor="est_cedula" className="form-label">Cédula</label>
                                    </div>
                                </div>
                                
                                
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="est_contrasena" placeholder="Contraseña" type="password" onChange={this.manejadorOnchange}/>
                                        <label htmlFor="est_contrasena" className="form-label">Contraseña</label>
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="est_nombres" placeholder="Nombres del estudiante" type="text" onChange={this.manejadorOnchange} />
                                        <label htmlFor="est_nombres" className="form-label">Nombres</label>
                                    </div>
                                </div>
                                
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="est_apellidos" placeholder="Apellidos del estudiante" type="text" onChange={this.manejadorOnchange} />
                                        <label htmlFor="est_apellidos" className="form-label">Apellidos</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="est_fechanacimiento" type="date" onChange={this.manejadorOnchange} />
                                        <label htmlFor="est_fechanacimiento" className="form-label">Fecha Nacimiento</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <select class="form-select" name="est_sexo" onChange={this.manejadorOnchange}>
                                            <option selected></option>
                                            <option value="1">Masculino</option>
                                            <option value="2">Femenino</option>
                                        </select>
                                        <label htmlFor="est_sexo" className="form-label">Sexo</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="est_direccion" placeholder="Dirección del estudiante" type="text" onChange={this.manejadorOnchange} />
                                        <label htmlFor="est_direccion" className="form-label">Dirección</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-primary" onClick={() => this.post()} >Guardar</button>
                                </div>
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-dark" onClick={() => this.salir()}>Cancelar</button>
                                </div>
                            </div>
                        
                        </form>
                        {this.state.error === true &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMsg}
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <NuevoEstudiantes {...props} navigate={navigate} />
}

export default WithNavigate