import { hover } from "@testing-library/user-event/dist/hover";
import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../img/A.jpg";
import uleam from "../img/uleam.png";
import "../assets/CSS/Login.css";


class Header extends React.Component {
    render() {
        return (
            <div >
                <br />

                <div className="position-relative" style={{ marginBottom: "30px" }}>

                    <nav className="navbar navbar-expand-lg" style={{ backgroundColor: 'transparent', marginBottom: '0' }}>
                        <div >
                            <img src={logo} style={{ width: '70px', height: '70px', margin: "3px" }} alt="logo" className="position-absolute top-50 start-0 translate-middle-y" />
                            <img src={uleam} style={{ width: '90px', height: '70px', margin: "3px" }} alt="uleam" className="position-absolute top-50 end-0 translate-middle-y" />

                            <div className="position-absolute top-50 start-50 translate-middle" >

                                <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ margin: "10px" }}>
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 " >
                                        <li className="nav-item" style={{ padding: "1px", margin: "5px", marginRight: "20px" }}>
                                            <a className="nav-link active" aria-current="page" href="/datosdocentes" ><b>Docentes</b></a>
                                        </li>
                                        <li className="nav-item" style={{ padding: "1px", margin: "5px", marginRight: "20px" }} >
                                            <a className="nav-link active" aria-current="page" href="/datosasignaturas" ><b>Asignaturas</b></a>
                                        </li>
                                        <li className="nav-item" style={{ padding: "1px", margin: "5px", marginRight: "20px" }}>
                                            <a className="nav-link active" aria-current="page" href="/datosestudiantes" ><b>Estudiantes</b></a>
                                        </li>
                                        <li className="nav-item" style={{ padding: "1px", margin: "5px", marginRight: "20px" }}>
                                            <a className="nav-link active" aria-current="page" href="/datosparalelos" ><b>Paralelos</b></a>
                                        </li>
                                        <li className="nav-item" style={{ padding: "1px", margin: "5px", marginRight: "20px" }}>
                                            <a className="nav-link active" aria-current="page" href="/datosdetalles"><b>Detalles</b></a>
                                        </li>
                                        <li className="nav-item" style={{ padding: "1px", margin: "5px", marginRight: "20px" }}>
                                            <a className="nav-link active" aria-current="page" href="/"><svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 512 512">
                                <path fill="#ff0000" d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 
                    20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 
                    0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 
                    0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 
                    32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"/>
                            </svg></a>
                                        </li>
                                    </ul>
                                </div>



                            </div>
                        </div>
                    </nav>

                </div>
                <hr />
            </div>
        );
    }
}

export default Header;