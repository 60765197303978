import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { urlApi } from "../../services/apirest.js";
import Header from "../Header.js";
let pagina = 1;
let total_paginas = 0;
let cadena = "";
class DatosDocentes extends React.Component {
    state = {
        registros: [],
        num_paginas: 0
    }

    pagina_siguiente = () => {
        let num_p = this.state.num_paginas[0].numpag;
        //console.log(num_p);
        if (num_p > pagina) {
            pagina += 1;
            let url = urlApi + "docentes.php?page=" + pagina + "&idinstitucion=" + localStorage.getItem("idusuario") + "&cadena=" + cadena;
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        registros: response.data[0]
                    })
                })
        }
    }

    pagina_anterior = () => {
        if (pagina !== 1) {
            pagina -= 1;
            let url = urlApi + "docentes.php?page=" + pagina + "&idinstitucion=" + localStorage.getItem("idusuario") + "&cadena=" + cadena;
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        registros: response.data[0]
                    })
                })
        }
    }

    buscarTexto = async e => {
        console.log(e.charCode);
        if (e.charCode === 13) {
            pagina = 1;
            cadena = e.target.value;
            this.componentDidMount();
        }
    }

    delete = (iddocentes) => {
        let url = urlApi + "docentes.php";
        let datos = {
            "token": localStorage.getItem("token"),
            "iddocentes": iddocentes,
            "metodo": "delete"
        }
        axios
            .post(url, datos)
            .then(response => {
                this.componentDidMount();
            })
            .catch(error => {
                console.log(error);
            })
    }

    componentDidMount = () => {
        //this.props.nuevoRegistro = true;
        let url = urlApi + "docentes.php?page=" + pagina + "&idinstitucion=" + localStorage.getItem("idusuario") + "&cadena=" + cadena;
        axios
            .get(url)
            .then(response => {
                this.setState({
                    registros: response.data[0],
                    num_paginas: response.data[1]
                })
                total_paginas = response.data[1][0].numpag;
                if (pagina > response.data[1][0].numpag) {
                    this.pagina_anterior();
                }
            })
    }

    clicRegistro(id) {
        //console.log(id);
        localStorage.setItem("IdRegistroSeleccionado", id);
        this.props.navigate('/editardocentes');
    }

    nuevoRegistro = () => {
        this.props.navigate('/nuevodocentes');
    }
    cambiarIdForaneo(codigo, dato) {
        const { EditarVariable } = this.props;
        EditarVariable(codigo, dato);
        const { cerrarModal } = this.props;
        cerrarModal();
        const { manejadorOnchange } = this.props;
        manejadorOnchange({ target: { name: 'fkdocentes', value: codigo } });
    }
    render() {
        return (
            <div>
                {this.props.NoModal === true ? (
                    <Header />
                ) : (
                    <div />
                )}
                <div className="container">
                <center><h3 style={{ fontFamily: "cambria", fontStyle: "italic", fontSize: "23px" }}><b>Bienvenid@ a la institución:</b> <h style={{color: "blue"}}>{localStorage.getItem('nombre_inst')}</h></h3></center>
                
                <div class="d-flex justify-content-around" style={{marginBottom: "20px"}}>
                    {this.props.NoModal === true ? (
                        <div style={{marginRight: "700px"}}>
                            <button type="button" onClick={this.nuevoRegistro} style={{ marginRight: "10px", background: "linear-gradient(to bottom, rgba(0, 120, 255, 1), rgba(46, 0, 255, 1))" }} className="btn btn-primary">Nuevo Docente</button>
                        </div>
                    ) : (
                        <div />
                    )}

                    <div>
                        <label  style={{marginRight: "10px"}}><svg xmlns="http://www.w3.org/2000/svg" height="15" width="15" viewBox="0 0 512 512"><path fill="#000000" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg></label>
                        <input type="text" onKeyPress={this.buscarTexto}></input>
                    </div>
                    </div>

                    <div className="container table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr className="text text-sm-center">
                                    {/* <th scope="col">ID</th> */}
                                    <th scope="col">Cédula</th>
                                    <th scope="col" className="col-sm-3">Nombres</th>
                                    <th scope="col">Teléfono</th>
                                    <th scope="col">Dirección</th>
                                    <th scope="col">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.registros.map((value, index) => {
                                    return (
                                        <tr key={index} >
                                            {/* <th scope="row">{value.iddocentes}</th> */}
                                            <td className="text text-sm-center">{value.doc_cedula}</td>
                                            <td>{value.doc_nombres}</td>
                                            <td className="text text-sm-center">{value.doc_telefono}</td>
                                            <td className="text text-sm-center">{value.doc_direccion}</td>
                                            <td className="text text-sm-center">
                                                {this.props.NoModal === true ? (
                                                    <div>
                                                        <svg onClick={() => this.clicRegistro(value.iddocentes)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-pencil" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#00abfb" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" />
                                                            <path d="M13.5 6.5l4 4" />
                                                        </svg>
                                                        <svg onClick={() => this.delete(value.iddocentes)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash-off" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff2825" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M3 3l18 18" />
                                                            <path d="M4 7h3m4 0h9" />
                                                            <path d="M10 11l0 6" />
                                                            <path d="M14 14l0 3" />
                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l.077 -.923" />
                                                            <path d="M18.384 14.373l.616 -7.373" />
                                                            <path d="M9 5v-1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                        </svg>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <svg onClick={() => this.cambiarIdForaneo(value.iddocentes, value.doc_nombres + " ")} xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 448 512">
                                                            <path fill="#36ff1e" d="M160 64c0-8.8 7.2-16 16-16s16 7.2 16 16V200c0 10.3 6.6 19.5 16.4 22.8s20.6-.1 26.8-8.3c3-3.9 7.6-6.4 12.8-6.4c8.8 0 16 7.2 16 16c0 10.3 6.6 
                                                                19.5 16.4 22.8s20.6-.1 26.8-8.3c3-3.9 7.6-6.4 12.8-6.4c7.8 0 14.3 5.6 15.7 13c1.6 8.2 7.3 15.1 15.1 18s16.7 1.6 23.3-3.6c2.7-2.1 6.1-3.4 9.9-3.4c8.8 0 16 7.2 16 16l0 
                                                                16V392c0 39.8-32.2 72-72 72H272 212.3h-.9c-37.4 0-72.4-18.7-93.2-49.9L50.7 312.9c-4.9-7.4-2.9-17.3 4.4-22.2s17.3-2.9 22.2 4.4L116 353.2c5.9 8.8 16.8 12.7 26.9 9.7s17-12.4 
                                                                17-23V320 64zM176 0c-35.3 0-64 28.7-64 64V261.7C91.2 238 55.5 232.8 28.5 250.7C-.9 270.4-8.9 310.1 10.8 339.5L78.3 440.8c29.7 44.5 79.6 71.2 133.1 71.2h.9H272h56c66.3 0 120-53.7 
                                                                120-120V288l0-16c0-35.3-28.7-64-64-64c-4.5 0-8.8 .5-13 1.3c-11.7-15.4-30.2-25.3-51-25.3c-6.9 0-13.5 1.1-19.7 3.1C288.7 170.7 269.6 160 248 160c-2.7 0-5.4 .2-8
                                                                 .5V64c0-35.3-28.7-64-64-64zm48 304c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304zm48-16c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 
                                                                 16-16V304c0-8.8-7.2-16-16-16zm80 16c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304z"/>
                                                        </svg>
                                                        {/* <svg onClick={() => this.cambiarIdForaneo(value.iddocentes, value.doc_nombres + " ")} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-pencil" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#00abfb" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" />
                                                                <path d="M13.5 6.5l4 4" />
                                                            </svg> */}
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <nav>
                            <center>
                                <button type="button" onClick={this.pagina_anterior} style={{ color: "white", marginRight: "10px", background: "linear-gradient(to bottom, rgba(46, 0, 255, 1), rgba(0, 251, 255, 1))" }} className="btn">Anterior</button>
                                <input type="text" readOnly style={{ marginRight: "10px", textAlign: "center", width: "120px" }} value={pagina + " de " + total_paginas} />
                                <button type="button" onClick={this.pagina_siguiente} style={{ color: "white", background: "linear-gradient(to bottom, rgba(46, 0, 255, 1), rgba(0, 251, 255, 1))" }} className="btn">Siguiente</button>
                            </center>
                        </nav>
                    </div>
                </div>
            </div>
        );
    }
}
function Navegacion(props) {
    let navigate = useNavigate();
    return <DatosDocentes {...props} navigate={navigate} />
}
export default Navegacion;