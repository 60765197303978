import React, { useState } from "react";
import { urlApi } from "../../services/apirest.js";
import axios from "axios";
import Header from "../Header.js";
import Datos_Docentes from "../docentes/Datos_Docentes.jsx";
import Datos_Asignaturas from "../asignaturas/Datos_Asignaturas.jsx";
import Datos_Paralelos from "../paralelos/Datos_Paralelos.jsx";
import modal from "../../assets/CSS/modal.css";
import { useNavigate } from 'react-router-dom';

class NuevoDetalles extends React.Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         mostrarModal: false,
    //         mostrarModal2: false,
    //         mostrarModal3: false
    //     };
    // }

    abrirModal = () => {
        this.setState({ mostrarModal: true });
    }

    abrirModal2 = () => {
        this.setState({ mostrarModal2: true });
    }
    abrirModal3 = () => {
        this.setState({ mostrarModal3: true });
    }

    cerrarModal = () => {
        this.setState({ mostrarModal: false });
    }

    cerrarModal2 = () => {
        this.setState({ mostrarModal2: false });
    }
    cerrarModal3 = () => {
        this.setState({ mostrarModal3: false });
    }

    state = {
        form: {
            "iddetalles": "",
            "fkdocentes": "",
            "fkasignaturas": "",
            "fkparalelos": "",
            "token": localStorage.getItem("token"),
            "metodo": "post"
        },
        mostrarModal: false,
        mostrarModal2: false,
        mostrarModal3: false
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    post = () => {
        const {notificacion} = this.props;
        let url = urlApi + "detalles.php";
        let datos = this.state.form
        axios
            .post(url, datos)
            .then(response => {
                notificacion("Los datos se han guardado correctamente");
                this.props.navigate('/datosdetalles');
            })
            .catch(error => {
                console.log(error);
            })
    }

    salir = () => {
        this.props.navigate('/datosdetalles');
    }

    componentDidMount() {
        const {EditarVariable} = this.props;
        EditarVariable("", "");
        const {EditarVariable2} = this.props;
        EditarVariable2("", "");
        const {EditarVariable3} = this.props;
        EditarVariable3("", "");
    }
 

    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="position-absolute top-1 start-50 translate-middle-x">
                    <div className="container">
                        <h2>Nuevo Detalle</h2>
                    </div>
                    <div className="container">
                        <form className="form-horizontal">
                            <div className="row">
                                <div hidden className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="iddetalles" className="form-label">ID</label>
                                        <input className="form-control" name="iddetalles" placeholder="ID del detalle" type="text" onChange={this.manejadorOnchange} readOnly />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3" >
                                        <input className="form-control" name="fkdocentes" placeholder="ID del docente" type="text" value={this.props.datoForaneo} onClick={this.abrirModal} />
                                        <label htmlFor="fkdocentes" className="form-label">Docente</label>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3" >
                                        <input className="form-control" name="fkasignaturas" placeholder="ID de la asignatura" type="text" value={this.props.datoForaneo2} onClick={this.abrirModal2} />
                                        <label htmlFor="fkasignaturas" className="form-label">Asignatura</label>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3" >
                                        <input className="form-control" name="fkparalelos" placeholder="ID del paralelo" type="text" value={this.props.datoForaneo3} onClick={this.abrirModal3} />
                                        <label htmlFor="fkparalelos" className="form-label">Paralelo</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-primary" onClick={() => this.post()}>Guardar</button>
                                </div>
                                <div className=" col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-dark" onClick={() => this.salir()}>Cancelar</button>
                                </div>
                            </div>
                        </form>
                        {this.state.error === true &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMsg}
                            </div>
                        }
                    </div>

                </div>
                {this.state.mostrarModal &&
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={this.cerrarModal}>&times;</span>
                            <Datos_Docentes EditarVariable={this.props.EditarVariable} cerrarModal={this.cerrarModal} manejadorOnchange={this.manejadorOnchange} />
                        </div>
                    </div>
                }
                {this.state.mostrarModal2 &&
                    <div className="modal">

                        <div className="modal-content">
                            <span className="close" onClick={this.cerrarModal2}>&times;</span>
                            <Datos_Asignaturas EditarVariable2={this.props.EditarVariable2} cerrarModal2={this.cerrarModal2} manejadorOnchange={this.manejadorOnchange} />
                        </div>
                    </div>
                }
                {this.state.mostrarModal3 &&
                    <div className="modal">

                        <div className="modal-content">
                            <span className="close" onClick={this.cerrarModal3}>&times;</span>
                            <Datos_Paralelos EditarVariable3={this.props.EditarVariable3} cerrarModal3={this.cerrarModal3} manejadorOnchange={this.manejadorOnchange} />
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }

}

function WithNavigate(props) {
    const [showModal, setShowModal] = useState(false);
    let navigate = useNavigate();
    return <NuevoDetalles {...props} navigate={navigate} showModal={showModal} setShowModal={setShowModal} />

}

export default WithNavigate