import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { urlApi } from "../../services/apirest.js";
import Headerdoc from "../Header_doc.js";
import '../../assets/CSS/App.css';
//var trimestre = localStorage.getItem('Trimestre'); 
class DatosCalificacionesDoc extends React.Component {
    state = {
        form: {
            "idcalificaciones": "",
            "calificacion1": "",
            "calificacion2": "",
            "calificacion3": "",
            "calificacion4": "",
            "promedio": "",
            "token": localStorage.getItem("token"),
            "metodo": "put",
            "trimestre": ""
        },
        registros: []

    }

    num_a_letra = (valor) => {
        const {cuant_a_cual} = this.props;
        return cuant_a_cual(valor)
    }
    validateCalificacion = (valor) => {
        const {notificacion} = this.props;
        if (isNaN(valor) || valor < 1 || valor > 10) {
            notificacion("Calificación inválida: Por favor, ingrese un número entre 1 y 10.");
            return false;
        }
        return true;
    }
    componentDidMount = () => {
        let iddetalle = localStorage.getItem('IdDetalle');

        let url = urlApi + "calificaciones2.php?iddetalle=" + iddetalle + "&trimestre=" + localStorage.getItem('Trimestre');
        axios
            .get(url)
            .then(response => {
                this.setState({
                    registros: response.data[0]
                })

            })
    }

    actualizarCalificacion1 = (id) => {
        const {cuant_a_cual} = this.props;
        let calificacion1 = parseFloat(document.getElementById("calificacion1" + id).value);
        if (!this.validateCalificacion(calificacion1)) {
            document.getElementById("calificacion1" + id).value = ""; // Limpiar valor inválido
        return;
    }
        let calificacion2 = parseFloat(document.getElementById("calificacion2" + id).value);
        let calificacion3 = parseFloat(document.getElementById("calificacion3" + id).value);
        let calificacion4 = parseFloat(document.getElementById("calificacion4" + id).value);
        if (isNaN(calificacion1)) {
            calificacion1 = 0;
        }
        if (isNaN(calificacion2)) {
            calificacion2 = 0;
        }
        if (isNaN(calificacion3)) {
            calificacion3 = 0;
        }
        if (isNaN(calificacion4)) {
            calificacion4 = 0;
        }
        console.log(calificacion1);
        let promedio = (calificacion1 + calificacion2 + calificacion3 + calificacion4) / 4;
        document.querySelector("#promedio" + id).value = promedio;
        document.querySelector("#cualitativa" + id).value = cuant_a_cual(promedio);
        this.setState({
            form: {
                idcalificaciones: id,
                calificacion1: calificacion1,
                promedio: promedio,
                token: localStorage.getItem("token"),
                metodo: "put",
                trimestre: localStorage.getItem('Trimestre')
            }
        })
        let url = urlApi + "calificaciones2.php";
        setTimeout(() => {
            console.log(this.state.form);
            axios
                .post(url, this.state.form)
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }

    actualizarCalificacion2 = (id) => {
        const {cuant_a_cual} = this.props;
        let calificacion1 = parseFloat(document.getElementById("calificacion1" + id).value);
        let calificacion2 = parseFloat(document.getElementById("calificacion2" + id).value);
        if (!this.validateCalificacion(calificacion2)) {
            document.getElementById("calificacion2" + id).value = ""; // Limpiar valor inválido
        return;
    }
        let calificacion3 = parseFloat(document.getElementById("calificacion3" + id).value);
        let calificacion4 = parseFloat(document.getElementById("calificacion4" + id).value);
        if (isNaN(calificacion1)) {
            calificacion1 = 0;
        }
        if (isNaN(calificacion2)) {
            calificacion2 = 0;
        }
        if (isNaN(calificacion3)) {
            calificacion3 = 0;
        }
        if (isNaN(calificacion4)) {
            calificacion4 = 0;
        }
        console.log(calificacion1);
        let promedio = (calificacion1 + calificacion2 + calificacion3 + calificacion4) / 4;
        document.querySelector("#promedio" + id).value = promedio;
        document.querySelector("#cualitativa" + id).value = cuant_a_cual(promedio);
        this.setState({
            form: {
                idcalificaciones: id,
                calificacion2: calificacion2,
                promedio: promedio,
                token: localStorage.getItem("token"),
                metodo: "put",
                trimestre: localStorage.getItem('Trimestre')
            }
        })
        let url = urlApi + "calificaciones2.php";
        setTimeout(() => {
            console.log(this.state.form);
            axios
                .post(url, this.state.form)
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
    actualizarCalificacion3 = (id) => {
        const {cuant_a_cual} = this.props;
        let calificacion1 = parseFloat(document.getElementById("calificacion1" + id).value);
        let calificacion2 = parseFloat(document.getElementById("calificacion2" + id).value);
        let calificacion3 = parseFloat(document.getElementById("calificacion3" + id).value);
        if (!this.validateCalificacion(calificacion3)) {
            document.getElementById("calificacion3" + id).value = ""; // Limpiar valor inválido
        return;
    }
        let calificacion4 = parseFloat(document.getElementById("calificacion4" + id).value);
        if (isNaN(calificacion1)) {
            calificacion1 = 0;
        }
        if (isNaN(calificacion2)) {
            calificacion2 = 0;
        }
        if (isNaN(calificacion3)) {
            calificacion3 = 0;
        }
        if (isNaN(calificacion4)) {
            calificacion4 = 0;
        }
         console.log(calificacion1);
        let promedio = (calificacion1 + calificacion2 + calificacion3 + calificacion4) / 4;
        document.querySelector("#promedio" + id).value = promedio;
        document.querySelector("#cualitativa" + id).value = cuant_a_cual(promedio);
        this.setState({
            form: {
                idcalificaciones: id,
                calificacion3: calificacion3,
                promedio: promedio,
                token: localStorage.getItem("token"),
                metodo: "put",
                trimestre: localStorage.getItem('Trimestre')
            }
        })
        let url = urlApi + "calificaciones2.php";
        setTimeout(() => {
            console.log(this.state.form);
            axios
                .post(url, this.state.form)
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }


    actualizarCalificacion4 = (id) => {
        const {cuant_a_cual} = this.props;
        let calificacion1 = parseFloat(document.getElementById("calificacion1" + id).value);
        let calificacion2 = parseFloat(document.getElementById("calificacion2" + id).value);
        let calificacion3 = parseFloat(document.getElementById("calificacion3" + id).value);
        let calificacion4 = parseFloat(document.getElementById("calificacion4" + id).value);
        if (!this.validateCalificacion(calificacion4)) {
            document.getElementById("calificacion4" + id).value = ""; // Limpiar valor inválido
        return;
    }
    if (isNaN(calificacion1)) {
        calificacion1 = 0;
    }
    if (isNaN(calificacion2)) {
        calificacion2 = 0;
    }
    if (isNaN(calificacion3)) {
        calificacion3 = 0;
    }
    if (isNaN(calificacion4)) {
        calificacion4 = 0;
    }
        console.log(calificacion1);
        let promedio = (calificacion1 + calificacion2 + calificacion3 + calificacion4) / 4;
        document.querySelector("#promedio" + id).value = promedio;
        document.querySelector("#cualitativa" + id).value = cuant_a_cual(promedio);
        this.setState({
            form: {
                idcalificaciones: id,
                calificacion4: calificacion4,
                promedio: promedio,
                token: localStorage.getItem("token"),
                metodo: "put",
                trimestre: localStorage.getItem('Trimestre')
            }
        })
        let url = urlApi + "calificaciones2.php";
        setTimeout(() => {
            console.log(this.state.form);
            axios
                .post(url, this.state.form)
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }

    
    calcularPromedio = (id) => {
        
        let calificacion1 = parseFloat(document.getElementById("calificacion1" + id).value);
        let calificacion2 = parseFloat(document.getElementById("calificacion2" + id).value);
        let calificacion3 = parseFloat(document.getElementById("calificacion3" + id).value);
        let calificacion4 = parseFloat(document.getElementById("calificacion4" + id).value);
       


        let promedio = (calificacion1 + calificacion2 + calificacion3 + calificacion4) / 4;
        
        document.querySelector("#promedio" + id).value = promedio;
        


        this.setState({
            form: {
                idcalificaciones: id,
                promedio: promedio,
                token: localStorage.getItem("token"),
                metodo: "put",
                trimestre: localStorage.getItem('Trimestre')
            }
        })
        let url = urlApi + "calificaciones2.php";
        setTimeout(() => {
            console.log(this.state.form);
            axios
                .post(url, this.state.form)
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }

    render() {
        return (
            <div>
                <Headerdoc />
                <div className="container">
                    <center><h3 style={{ fontFamily: "cambria", fontStyle: "italic", fontSize: "23px" }}><b>Trimestre:</b> <h style={{ color: "red" }}>{localStorage.getItem('Trimestre')}</h>     <b>Asignatura:</b> <h style={{ color: "red" }}>{localStorage.getItem('asi')}</h> </h3></center>

                    <a href={urlApi + 'reportes/reporte.php?iddetalle=' + localStorage.getItem('IdDetalle') + '&trimestre=' + localStorage.getItem('Trimestre') + "&asi=" + localStorage.getItem('asi') + "&ins=" + localStorage.getItem("nombre_inst")} target="_blank"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-analytics" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#106cfc" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                        <line x1="9" y1="17" x2="9" y2="12" />
                        <line x1="12" y1="17" x2="12" y2="16" />
                        <line x1="15" y1="17" x2="15" y2="14" />
                    </svg></a>
                    <div className="container">
                        <table className="table table-hover">
                            <thead>
                                <tr className="text text-sm">
                                    <th hidden scope="col">ID</th>
                                    <th scope="col">Estudiante</th>
                                    <th scope="col">Calificación1</th>
                                    <th scope="col">Calificación2</th>
                                    <th scope="col">Calificación3</th>
                                    <th scope="col">Calificación4</th>
                                    <th scope="col">Promedio</th>
                                    <th scope="col" className="col-sm-1">Calificación Cualitativa</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.registros.map((value, index) => {

                                    return (
                                        <tr key={index} className="text text-sm">
                                            <th hidden scope="row">{value.idcalificaciones}</th>
                                            <td scope="row">{value.nombre_est}</td>
                                            <td>
                                                <input className="form-control" id={"calificacion1" + value.idcalificaciones} type="text" style={{ width: '70px' }} defaultValue={value.cal1} onBlur={() => this.actualizarCalificacion1(value.idcalificaciones)} />
                                            </td>
                                            <td>
                                                <input className="form-control" id={"calificacion2" + value.idcalificaciones} type="text" style={{ width: '70px' }} defaultValue={value.cal2} onBlur={() => this.actualizarCalificacion2(value.idcalificaciones)} />
                                            </td>
                                            <td>
                                                <input className="form-control" id={"calificacion3" + value.idcalificaciones} type="text" style={{ width: '70px' }} defaultValue={value.cal3} onBlur={() => this.actualizarCalificacion3(value.idcalificaciones)} />
                                            </td>
                                            <td>
                                                <input className="form-control" id={"calificacion4" + value.idcalificaciones} type="text" style={{ width: '70px' }} defaultValue={value.cal4} onBlur={() => this.actualizarCalificacion4(value.idcalificaciones)} />
                                            </td>
                                            <td>
                                                <input className="form-control" disabled id={"promedio" + value.idcalificaciones} type="text" style={{ width: '70px' }} defaultValue={value.promedio} />
                                            </td>
                                            <td>
                                                <input className="form-control" disabled id={"cualitativa" + value.idcalificaciones} type="text" style={{ width: '70px'  }} defaultValue={this.num_a_letra(value.promedio)} />
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
function Navegacion(props) {
    let navigate = useNavigate();
    return <DatosCalificacionesDoc {...props} navigate={navigate} />
}
export default Navegacion;