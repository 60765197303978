import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { urlApi } from "../../services/apirest.js";
import Header from "../Header.js";
let pagina = 1;
let total_paginas = 0;
let cadena = "";
class DatosDetalles extends React.Component {
    state = {
        registros: [],
        num_paginas: 0
    }

    pagina_siguiente = () => {
        let num_p = this.state.num_paginas[0].numpag;
        //console.log(num_p);
        if (num_p > pagina) {
            pagina += 1;
            let url = urlApi + "detalles.php?page=" + pagina + "&id=" + localStorage.getItem("idusuario");
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        registros: response.data[0]
                    })
                })
        }
    }

    pagina_anterior = () => {
        if (pagina !== 1) {
            pagina -= 1;
            let url = urlApi + "detalles.php?page=" + pagina + "&id=" + localStorage.getItem("idusuario");
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        registros: response.data[0]
                    })
                })
        }
    }

    buscarTexto = async e => {
        console.log(e.charCode);
        if (e.charCode === 13) {
            pagina = 1;
            cadena = e.target.value;
            this.componentDidMount();
        }
    }

    delete = (iddetalles) => {
        let url = urlApi + "detalles.php";
        let datos = {
            "token": localStorage.getItem("token"),
            "iddetalles": iddetalles,
            "metodo": "delete"
        }
        axios
            .post(url, datos)
            .then(response => {
                this.componentDidMount();
            })
            .catch(error => {
                console.log(error);
            })
    }

    componentDidMount = () => {
        let url = urlApi + "detalles.php?page=" + pagina + "&id=" + localStorage.getItem("idusuario");
        axios
            .get(url)
            .then(response => {
                this.setState({
                    registros: response.data[0],
                    num_paginas: response.data[1]
                })
                total_paginas = response.data[1][0].numpag;
                if (pagina > response.data[1][0].numpag) {
                    this.pagina_anterior();
                }
            })
    }

    clicRegistro(id) {
        //console.log(id);
        localStorage.setItem("IdRegistroSeleccionado", id);
        this.props.navigate('/editardetalles');
    }

    ircalificaciones(id, trimestre, asi_nombre) {
        //console.log(id);
        localStorage.setItem("IdDetalle", id);
        localStorage.setItem("Trimestre", trimestre);
        localStorage.setItem("asi", asi_nombre);
        this.props.navigate('/datoscalificaciones');
    }

    nuevoRegistro = () => {
        this.props.navigate('/nuevodetalles');
    }

    render() {
        return (
            <div>
                <Header />
                <div class="container">
                    <div class="d-flex justify-content-around" style={{marginBottom: "20px"}}>
                    <div style={{marginRight: "700px"}}>
                        <button type="button" onClick={this.nuevoRegistro} style={{ marginRight: "10px", background:"linear-gradient(to bottom, rgba(0, 120, 255, 1), rgba(46, 0, 255, 1))" }} className="btn btn-primary">Nuevo Detalle</button>
                    </div>
                    <div >
                    <label style={{marginRight: "10px"}}><svg xmlns="http://www.w3.org/2000/svg" height="15" width="15" viewBox="0 0 512 512"><path fill="#000000" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg></label>
                        <input type="text" onKeyPress={this.buscarTexto}></input>
                    </div>
                    </div>
                    <div className="container table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr className="text text-sm-center">
                                    <th hidden scope="col">ID</th>
                                    <th scope="col">Docentes</th>
                                    <th scope="col">Asignaturas</th>
                                    <th scope="col">Paralelos</th>
                                    <th scope="col">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.registros.map((value, index) => {
                                    return (
                                        <tr key={index} className="text text-sm-center" >
                                            <th hidden scope="row">{value.iddetalles}</th>
                                            <td>{value.doc_nombres}</td>
                                            <td>{value.asi_nombre}</td>
                                            <td>{value.paralelos}</td>
                                            <td >
                                                <svg onClick={() => this.clicRegistro(value.iddetalles)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-pencil" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#00abfb" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" />
                                                    <path d="M13.5 6.5l4 4" />
                                                </svg>
                                                <svg onClick={() => this.delete(value.iddetalles)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash-off" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff2825" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M3 3l18 18" />
                                                    <path d="M4 7h3m4 0h9" />
                                                    <path d="M10 11l0 6" />
                                                    <path d="M14 14l0 3" />
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l.077 -.923" />
                                                    <path d="M18.384 14.373l.616 -7.373" />
                                                    <path d="M9 5v-1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                </svg>
                                                <svg onClick={() => this.ircalificaciones(value.iddetalles, "1t", value.asi_nombre)} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#24d50f" class="bi bi-1-square" viewBox="0 0 16 16" style={{marginLeft: "5px"}}>
                                                    <path d="M9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383z"/>
                                                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
                                                </svg>
                                                <svg onClick={() => this.ircalificaciones(value.iddetalles, "2t", value.asi_nombre)} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ff7c22" class="bi bi-2-square" viewBox="0 0 16 16" style={{marginLeft: "5px"}}>
                                                    <path d="M6.646 6.24v.07H5.375v-.064c0-1.213.879-2.402 2.637-2.402 1.582 0 2.613.949 2.613 2.215 0 1.002-.6 1.667-1.287 2.43l-.096.107-1.974 2.22v.077h3.498V12H5.422v-.832l2.97-3.293c.434-.475.903-1.008.903-1.705 0-.744-.557-1.236-1.313-1.236-.843 0-1.336.615-1.336 1.306"/>
                                                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
                                                </svg>
                                                <svg onClick={() => this.ircalificaciones(value.iddetalles, "3t", value.asi_nombre)} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ff29f5" class="bi bi-3-square" viewBox="0 0 16 16" style={{marginLeft: "5px"}}>
                                                    <path d="M7.918 8.414h-.879V7.342h.838c.78 0 1.348-.522 1.342-1.237 0-.709-.563-1.195-1.348-1.195-.79 0-1.312.498-1.348 1.055H5.275c.036-1.137.95-2.115 2.625-2.121 1.594-.012 2.608.885 2.637 2.062.023 1.137-.885 1.776-1.482 1.875v.07c.703.07 1.71.64 1.734 1.917.024 1.459-1.277 2.396-2.93 2.396-1.705 0-2.707-.967-2.754-2.144H6.33c.059.597.68 1.06 1.541 1.066.973.006 1.6-.563 1.588-1.354-.006-.779-.621-1.318-1.541-1.318"/>
                                                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
                                                </svg>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <nav>
                            <center>
                                <button type="button" onClick={this.pagina_anterior} style={{ color:"white", marginRight: "10px", background:"linear-gradient(to bottom, rgba(46, 0, 255, 1), rgba(0, 251, 255, 1))" }} className="btn">Anterior</button>
                                <input type="text" readOnly style={{ marginRight: "10px", textAlign: "center", width: "120px" }} value={pagina + " de " + total_paginas} />
                                <button type="button" onClick={this.pagina_siguiente} style={{ color:"white", background:"linear-gradient(to bottom, rgba(46, 0, 255, 1), rgba(0, 251, 255, 1))"}} className="btn">Siguiente</button>
                            </center>
                        </nav>
                    </div>
                </div>
            </div>
        );
    }
}
function Navegacion(props) {
    let navigate = useNavigate();
    return <DatosDetalles {...props} navigate={navigate} />
}
export default Navegacion;