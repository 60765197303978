import React, { useState } from "react";
import { urlApi } from "../../services/apirest.js";
import axios from "axios";
import Header from "../Header.js";
import { useNavigate } from 'react-router-dom';
import Datos_Docentes from "../docentes/Datos_Docentes.jsx";

class EditarDetalles extends React.Component {
    
    abrirModal = () => {
        this.setState({ mostrarModal: true });
    }

    cerrarModal = () => {
        this.setState({ mostrarModal: false });
    }

    state = {
        form: {
            "iddetalles": "",
            "fkdocentes": "",
            "fkasignaturas": "",
            "fkparalelos": "",
            "token": localStorage.getItem("token"),
            "metodo": "put"
        },
        mostrarModal: false,
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    componentDidMount = () => {
        

        let idRegistro = localStorage.getItem("IdRegistroSeleccionado");
        let url = urlApi + "detalles.php?id=" + idRegistro;
        axios
            .get(url)
            .then(response => {
                this.setState({
                    form: {
                        iddetalles: response.data[0].iddetalles,
                        doc_nombres: response.data[0].doc_nombres,
                        asi_nombre: response.data[0].asi_nombre,
                        paralelos: response.data[0].paralelos,
                        token: localStorage.getItem("token"),
                        metodo: "put"
                    }
                    
                })
                const {EditarVariable} = this.props;
                EditarVariable("", "");
            })
            .catch(error => {
                console.log("Error de conexion");
            })
    }

    put = () => {
        let url = urlApi + "detalles.php";
        let datos = this.state.form
        axios
            .post(url, datos)
            .then(response => {
                this.props.navigate('/datosdetalles');
            })
            .catch(error => {
                console.log(error);
            })
    }

    salir = () => {
        this.props.navigate('/datosdetalles');
    }


    render() {
        const formData = this.state.form;
        return (
            <React.Fragment>
                <Header />
                <div className="position-absolute top-1 start-50 translate-middle-x">
                    <div className="container">
                        <h2>Editar Detalle</h2>
                    </div>
                    <div className="container">
                        <form className="form-horizontal">
                            <div className="row"> 
                                <div hidden className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="iddetalles" className="form-label">ID</label>
                                        <input className="form-control" name="iddetalles" placeholder="ID del detalle" type="text" defaultValue={formData.iddetalles} onChange={this.manejadorOnchange} readOnly/>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input className="form-control" name="fkdocentes" placeholder="ID de docente" type="text" defaultValue={formData.doc_nombres} value={this.props.datoForaneo} onClick={this.abrirModal} />
                                        <label htmlFor="fkdocentes" className="form-label">Docente</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input disabled className="form-control" name="fkasignaturas" placeholder="ID de la asignatura" type="text" defaultValue={formData.asi_nombre} onChange={this.manejadorOnchange} />
                                        <label htmlFor="fkasignaturas" className="form-label">Asignatura</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1 form-floating mb-3">
                                        <input disabled className="form-control" name="fkparalelos" placeholder="ID del paralelo" type="text" defaultValue={formData.paralelos} onChange={this.manejadorOnchange} />
                                        <label htmlFor="fkparalelos" className="form-label">Paralelo</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-primary" onClick={() => this.put()} style={{ marginRight: "10px" }}>Editar</button>
                                </div>
                                <div className="col-6 text-center d-grid gap-2">
                                    <button type="button" className="btn btn-dark" onClick={() => this.salir()}>Salir</button>
                                </div>
                            </div>
                        </form>
                        {this.state.error === true &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMsg}
                            </div>
                        }
                    </div>
                </div>
                {this.state.mostrarModal &&
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={this.cerrarModal}>&times;</span>
                            <Datos_Docentes EditarVariable={this.props.EditarVariable} cerrarModal={this.cerrarModal} manejadorOnchange={this.manejadorOnchange} />
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }

}

function WithNavigate(props) {
    const [showModal, setShowModal] = useState(false);
    let navigate = useNavigate();
    return <EditarDetalles {...props} navigate={navigate} showModal={showModal} setShowModal={setShowModal}/>
}

export default WithNavigate