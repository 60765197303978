import React from "react";
import "../assets/CSS/Login.css";
import { urlApi } from "../services/apirest.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import background from "../img/fondoLogin.jpg";
import logo from "../img/A.jpg";

class Login extends React.Component {
    state = {
        form: {
            //"usuario": "1315139129",
            "usuario": "",
            "contrasena": "",
            "tipo": "Docente"
        },
        error: false,
        errorMsg: ""
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    manejadorLogin = () => {
        let url = urlApi + "auth.php";
        axios.post(url, this.state.form)
            .then(response => {

                console.log(this.state.form.tipo)
                if (response.data.status === "ok") {
                    if (this.state.form.tipo === "Administrador") {
                        localStorage.setItem("token", response.data.result.token);
                        localStorage.setItem("idusuario", response.data.result.idusuario);
                        localStorage.setItem("nombre_inst", response.data.result.nombre_inst);
                        this.props.navigate("/datosdocentes");
                    } if (this.state.form.tipo === "Docente") {
                        localStorage.setItem("token", response.data.result.token);
                        localStorage.setItem("idusuario", response.data.result.idusuario);
                        this.props.navigate("/datosdetallesdoc");
                    }

                    // window.location.href = "/dashboard";
                } else {
                    this.setState({
                        error: true,
                        errorMsg: response.data.result.error_msg
                    })

                }
            })
            .catch(error => {
                this.setState({
                    error: false,
                    errorMsg: "Error de conexion"
                })
            })
    }



    render() {
        return (
            <React.Fragment>
                <body className="fondo">

                    <section className="vh-50 py-5">
                        <div className="container-fluid h-custom">
                            <div className="col d-flex justify-content-center  h-100 ">
                                <div className="box1 col-10 col-md-6 col-lg-6 col-xl-5 px-5 py-5" style={{ background: "#ffffff", borderBottomLeftRadius: "20px", borderTopLeftRadius: "20px" }}>
                                    <form>
                                        {/* IMAGEN LOGO */}
                                        <div className="text-center my-10">
                                            <img src={logo}
                                                style={{ width: '175px' }} alt="logo" />
                                            <h4 className="nombre mt-2 mb-5 pb-1">BIENVENIDO</h4>
                                        </div>
                                        <div className="form-floating mb-4 my-5">
                                            <input type="text" id="form3Example3" className="form-control form-control-lg" name="usuario"
                                                placeholder="Ingrese su usuario" onChange={this.manejadorOnchange} />
                                            <label className="form-label" htmlFor="form3Example3">Usuario</label>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input type="password" id="form3Example4" className="form-control form-control-lg" name="contrasena"
                                                placeholder="Ingrese su contraseña" onChange={this.manejadorOnchange} />
                                            <label className="form-label" htmlFor="form3Example4">Contraseña</label>
                                        </div>
                                        <div className="col-12 col-sm-6" style={{ width: "100%" }}>
                                            <div className="mb-1 form-floating mb-3">
                                                <select class="form-select" name="tipo" onChange={this.manejadorOnchange}>
                                                   
                                                    <option value="Docente" >Docente</option>
                                                    <option value="Administrador">Administrador</option>
                                                </select>
                                                <label htmlFor="tipo" className="form-label">Rol</label>
                                            </div>
                                        </div>
                                        <div className="wrapper d-grid gap-2 text-center text-lg-start mt-4 pt-2" class="">
                                            <button type="button" className="botonLogin btn btn-lg" onClick={this.manejadorLogin}>Iniciar sesión
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </button>
                                        </div>
                                    </form>
                                    {this.state.error === true &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMsg}
                                        </div>
                                    }
                                </div>
                                <div className="box2 d-none d-lg-block col-4 col-md-6 col-lg-6 col-xl-5">
                                    <img src={background} style={{ backgroundRepeat: 'no-repeat', width: '100%', height: '100%', borderBottomRightRadius: "20px", borderTopRightRadius: "20px" }} />
                                </div>
                            </div>
                        </div>
                    </section>

                </body>
            </React.Fragment>
        );
    }


}
function WithNavigate(props) {
    let navigate = useNavigate();
    return <Login {...props} navigate={navigate} />
}
export default WithNavigate;

